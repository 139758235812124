import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import {
  Banner, Bottom, Container, Deadline,
  Info, ScheduleLabel, Title, Top,
  ContactHRContainer, ContactHRIcon, ContactHRLabel,
} from './style';
import ExampleImageCard from '../../assets/img/img-card.jpg';
import Arial from '../../typography/arial';
import { getValueVocabulary } from '../../../utils/getValueVocabulary';
import dateFormat from '../../../utils/date-format';
import durationFormatHour from '../../../utils/duration-format-hour';
import { filterLanguages, isArabic } from '../../../utils/locale';
import { getExactLabel } from '../../../utils/common';

const CardLearningEvents = ({
  item, onClickCard, vocabulary,
}) => {
  const [data, setData] = useState(item);

  useEffect(() => {
    setData(item.values);
  }, [item]);

  const getStatusValue = () => {
    if (data?.deadline) {
      const now = new Date();
      const isExpired = moment(new Date(data.deadline)).isSameOrBefore(now);
      const isExpiring = moment(new Date(data.deadline)).diff(now, 'days') <= 7;
      if (isExpired) return 'statusExpired';
      if (isExpiring) return 'statusExpiring';
    }
    return '';
  };

  const handleOnClickCard = () => {
    if (onClickCard && getStatusValue() !== 'statusExpired') onClickCard();
  };

  return (
    <Container arabic={isArabic()} onClick={handleOnClickCard} cover={ExampleImageCard} isExpired={getStatusValue() === 'statusExpired'}>
      {getStatusValue() && <Banner alt={getValueVocabulary(vocabulary[getStatusValue()], getStatusValue()).length >= 13 ? true : undefined}><Arial type="bannerCardLearningEvents" vocabulary={getStatusValue()}>{getValueVocabulary(vocabulary[getStatusValue()], getStatusValue())}</Arial></Banner>}
      <Top>
        {getStatusValue() !== 'statusExpired' ? (
          <>
            <ScheduleLabel alt={filterLanguages(['UK']) ? true : undefined}>{getValueVocabulary(vocabulary.toBeScheduledLabel, 'toBeScheduledLabel')}</ScheduleLabel>
            <Deadline>
              {
                isArabic() ? (
                  <span>
                    {dateFormat(data?.deadline, '', getValueVocabulary(vocabulary.dateFormat, ''), false)}
                    {` ${getValueVocabulary(vocabulary.toBeScheduledDate, 'toBeScheduledDate')}`}
                  </span>
                ) : (
                  <span>
                    {getValueVocabulary(vocabulary.toBeScheduledDate, 'toBeScheduledDate')} {dateFormat(data?.deadline, '', getValueVocabulary(vocabulary.dateFormat, ''), false)}
                  </span>
                )
              }
            </Deadline>
          </>
        )
          : (
            <ContactHRContainer>
              <ContactHRIcon className="icon-icon-alert" />
              <ContactHRLabel><Arial type="contactHRLabel" vocabulary="contactHRLabel">{getValueVocabulary(vocabulary.contactHRLabel, 'contactHRLabel')}</Arial></ContactHRLabel>
            </ContactHRContainer>
          )
        }
      </Top>
      <Bottom>
        <Title>{data?.title}</Title>
        <Info>
          {isArabic() ? (
            <span>
              {getExactLabel(vocabulary, data?.slotSize, 'sessionLabel', 'sessionsLabel')} {data.slotSize} | {durationFormatHour(data.duration)} | {getValueVocabulary(vocabulary.tojName, 'tojName')}
            </span>
          ) : (
            <span>
              {getValueVocabulary(vocabulary.tojName, 'tojName')} | {durationFormatHour(data?.duration)} | {data?.slotSize} {getExactLabel(vocabulary, data?.slotSize, 'sessionLabel', 'sessionsLabel')}
            </span>
          )}
        </Info>
      </Bottom>
    </Container>
  );
};

CardLearningEvents.propTypes = {
  item: PropTypes.object.isRequired,
  onClickCard: PropTypes.func,
};


export default connect(
  state => {
    const { vocabulary } = state.app;
    return { vocabulary };
  }
)(CardLearningEvents);
