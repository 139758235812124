import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';

import { push } from 'redux-first-history';
import { Table, TableCard } from '../../../ui/components';
import { Container } from '../../../ui/gridSystem';
import { BackButton, CustomSelect, NoResult } from '../../../ui/atoms';
import {
  TitleHeader, HeaderContainer, Filters, NotificationWrapper,
  Title, Description, Type, ReadAll, InfoType, InfoTitle, InfoDate, Date,
} from './style';
import Arial from '../../../ui/typography/arial';
import { withMediaQueries } from '../../hoc/withMediaQueries';
import { NOTIFICATION_GET, NOTIFICATION_READ } from '../../redux/actions/notifications';
import routes from '../../../routes';
import { Status } from '../../../ui/components/NavBar/style';
import { getValueVocabulary } from '../../../utils/getValueVocabulary';
import { isArabic } from '../../../utils/locale';
import { parseQueryParams } from '../../../utils/queryParams';

const Notifications = ({
  notifications, types, mediaIsPhone, getNotification, readNotification,
  pushUrl, vocabulary,
}) => {
  const [dataset, setDataset] = useState(Table.createData(notifications));
  const [selectedFilters, setSelectedFilters] = useState();
  const [filters, setFilters] = useState([]);

  useEffect(() => {
    setDataset(Table.createData(notifications || []));
  }, [notifications]);

  useEffect(() => {
    if (types.length) {
      setFilters([...types].map(filter => ({
        ...filter,
        label: getValueVocabulary(vocabulary[filter.label], filter.label),
      })));
    }
  }, [types, vocabulary]);

  useEffect(() => {
    window.scrollTo(0, 0);
    getNotification({ type: selectedFilters?.value });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFilters]);


  const handleOnClickRow = ({
    id, node_id, type, status, user_role: role, tutee_id, deadline,
  }) => {
    if (status === 'not_read') readNotification(id);
    switch (type) {
      case 'selection': {
        pushUrl(`${routes.mandatorytraining.path}/${node_id}`);
        break;
      }
      case 'collection': {
        pushUrl(`${routes.optionalcourses.path}/${node_id}`);
        break;
      }
      case 'virtual_room': {
        pushUrl(`${routes.remoteclassroom.path}/${node_id}`);
        break;
      }
      case 'live_event':
      case 'live_session':
      {
        const agenda_type = type;
        pushUrl(`${routes.liveclassroom.path}/${node_id}${parseQueryParams({
          agenda_type
        })}`);
        break;
      }
      case 'toj': {
        pushUrl(`${routes.toj.path}/${node_id}/${parseQueryParams({ role, tutee_id, deadline })}`);
        break;
      }
      default:
        break;
    }
  };

  const tableItemLabels = {
    toj: getValueVocabulary(vocabulary.tojName, 'tojName'),
    virtual_room: getValueVocabulary(vocabulary.remoteClassroomName, 'remoteClassroomName'),
    selection: getValueVocabulary(vocabulary.mandatoryTraining, 'mandatoryTraining'),
    collection: getValueVocabulary(vocabulary.optionalCourses, 'optionalCourses'),
    live_event: getValueVocabulary(vocabulary.liveClassroomEventName, 'liveClassroomEventName'),
    live_session: getValueVocabulary(vocabulary.liveClassroomName, 'liveClassroomEventName'),
    remote_classroom_event: getValueVocabulary(vocabulary.liveClassroomName, 'remoteClassroomEventName')
  };

  const newColumn = ({
    title, field, cell, width, sortable = true,
  }) => ({
    title, field, cell, width, sortable,
  });

  const cols = [
    newColumn({
      // eslint-disable-next-line react/display-name
      title: 'Type', field: 'type', cell: (item) => <InfoType onClick={() => handleOnClickRow(item)}>{item.status === 'not_read' && <Status />}<Type arabic={isArabic()} notRead={item.status === 'not_read'}>{tableItemLabels[item.type]}</Type></InfoType>, width: '20%', sortable: false,
    }),
    newColumn({
      // eslint-disable-next-line react/display-name
      title: 'Date', field: 'date', cell: (item) => <InfoDate onClick={() => handleOnClickRow(item)}><Date notRead={item.status === 'not_read'}>{moment(item.date * 1000).format('DD/MM/YYYY - h:mm A')}</Date></InfoDate>, width: '20%', sortable: false,
    }),
    newColumn({
      // eslint-disable-next-line react/display-name
      title: 'Title', field: 'title', cell: (item) => <Description onClick={() => handleOnClickRow(item)}><Title notRead={item.status === 'not_read'}>{item.title}</Title><InfoTitle>{item.message}</InfoTitle></Description>, width: '60%', sortable: false,
    }),
  ];

  const handleReadAll = () => {
    readNotification('all');
  };


  return (
    <Container>
      <NotificationWrapper arabic={isArabic()}>
        <Arial type="sectionNotificationTitle" configuration={{ textAlign: 'center' }} vocabulary="notificationsTitle">{getValueVocabulary(vocabulary.notificationsTitle, 'notificationsTitle')}</Arial>
        <HeaderContainer>
          <TitleHeader>
            <BackButton />
            {notifications.length > 0 && (
              <ReadAll onClick={() => handleReadAll()}>
                <Arial type="newsAttachment">{getValueVocabulary(vocabulary.markAsRead, 'markAsRead')}</Arial>
              </ReadAll>
            )}
          </TitleHeader>
          {notifications.length > 0 && (
            <Filters>
              <Arial type="newsTitle">{getValueVocabulary(vocabulary.filterByType, 'filterByType')}</Arial>
              <CustomSelect
                isClearable
                options={filters}
                onChange={newValue => setSelectedFilters(newValue)}
              />
            </Filters>
          )}
        </HeaderContainer>
        {notifications.length > 0 ? (
          <>
            {mediaIsPhone ? (
              <TableCard type="notifications" data={notifications} onClick={handleOnClickRow} />
            ) : (
              <Table columns={Table.createColumns(cols)} data={dataset} onRowClick={handleOnClickRow} />
            )}
          </>
        ) : (
          <NoResult
            text={getValueVocabulary(vocabulary.noNotificationsFound, 'noNotificationsFound')}
          />
        )}
      </NotificationWrapper>
    </Container>
  );
};

Notifications.propTypes = {
  // HOC withMediaQueries
  mediaIsPhone: PropTypes.bool.isRequired,

  //HOC (connect, state)
  notifications: PropTypes.array,
  types: PropTypes.array,
  vocabulary: PropTypes.object,

  // HOC (connect, dispatch)
  getNotification: PropTypes.func.isRequired,
  readNotification: PropTypes.func.isRequired,
  pushUrl: PropTypes.func.isRequired,
};

export default connect(
  state => {
    const { notifications: { data: { notifications = [], types = [] } = [] } = {} } = state;
    const { vocabulary } = state.app;
    return {
      notifications,
      types,
      vocabulary,
    };
  },
  dispatch => ({
    getNotification: (filters) => dispatch({ type: NOTIFICATION_GET._REQUEST, filters }),
    readNotification: (id) => dispatch({ type: NOTIFICATION_READ._REQUEST, id }),
    pushUrl: url => dispatch(push(url)),
  })
)(withMediaQueries(Notifications));
