import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import moment from 'moment';
import { connect } from 'react-redux';
import { push } from 'redux-first-history';
import queryString from 'query-string';

import { isMobile } from 'react-device-detect';
import {
  LIVE_CLASSROOM_CONTENT_GET, LIVE_CLASSROOM_LIKE_DISLIKE,
} from '../../redux/actions/liveclassroom';

import { Col, Container, Row } from '../../../ui/gridSystem';
import {
  Background,
  Title,
  OverviewContainer,
  Text,
  RightContainer,
  RightContainerItems,
  SectionContainer,
  IconCompleted,
  SlotTitle,
  SessionDuration,
  Banner,
  BannerContainer,
  BannerInfo,
  Wrapper,
  IconWrapper,
  BoxAddress
} from './style';

import Arial from '../../../ui/typography/arial';
import { BackButton, BoxFinalTest, Tabs } from '../../../ui/atoms';
import { GuideboookCard, TeacherBox } from '../../../ui/components';
import {
  GuidebookItemColumn,
  GuidebookListColumn,
  RowGuidebook,
} from '../Guidebook/style';
import AgendaTimeline from '../../../ui/components/AgendaTimeline';
import durationFormatHour from '../../../utils/duration-format-hour';
import { getValueVocabulary } from '../../../utils/getValueVocabulary';
import {
  CourseNotAvailable,
  InfoContainer, InfoDescription, InfoTitle, TabsContainer,
} from '../Toj/style';
import { withMediaQueries } from '../../hoc/withMediaQueries';
import LikeDislikeBox from '../../../ui/components/LikeDislikeBox/index';
import { isArabic } from '../../../utils/locale';
import { dateFormatCalendar } from '../../../utils/common';
import { getExactLabel } from '../../../utils/common';

const LiveClassroom = ({
  heroImage, mediaIsLaptop, mediaIsTablet, getLiveClassroomContent,
  id, selected_classroom: {
    title, descriptionAgenda, materials, /*agenda, conferenceLink,*/ cover, goal = {}, sections = [], teachers = [], duration, slots, completed, tutee = [], feedback, type
  }, liveLocation, contentNotAvailable, user_type, location, vocabulary, setLiveClassroomFeedback,
}) => {
  const { tutee_id } = queryString.parse(location.search);
  const { agenda_type } = queryString.parse(location.search);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (user_type === 'tutor') {
      getLiveClassroomContent({ id, tutee_id });
    } else {
      getLiveClassroomContent({ id });
    }
  }, [getLiveClassroomContent, id, tutee_id, user_type]);

  const defaultTabs = [
    { label: getValueVocabulary(vocabulary.overviewLabel, 'overviewLabel'), value: 'overview' },
    { label: getValueVocabulary(vocabulary.materialsLabel, 'materialsLabel'), value: 'materials' },
  ];

  const [activeTab, setActiveTab] = useState(defaultTabs[0]);

  const onChangeTab = tab => {
    setActiveTab(tab);
  };

  const handleOnchangeLikeDislike = (value) => {
    setLiveClassroomFeedback({ feedback_value: value, id, tutee_id });
  };

  const getSessionsDuration = (slots) => {
    const startDate = new Date(slots[0]?.startDateTimestamp);
    const endDate = new Date(slots[slots?.length - 1].endDateTimestamp);
    return `${moment(startDate).format(getValueVocabulary(vocabulary.dateFormat, 'DD/MM/YYYY'))} - ${moment(endDate).format(getValueVocabulary(vocabulary.dateFormat, 'DD/MM/YYYY'))}`;
  };

  return (
    <Wrapper arabic={isArabic()}>
      {
        !contentNotAvailable ? (
          <React.Fragment>
            <Banner>
              <Background img={heroImage || cover} />
              <BannerContainer hasSlot={!!(slots || []).length}>
                <BackButton />
                {(slots || []).length && (
                  <SessionDuration>
                    <Arial type="tojSessionDuration">{getSessionsDuration(slots)}</Arial>
                  </SessionDuration>
                )}
                <Arial
                  vocabulary="newsDetailTitle"
                  type="newsDetailTitle"
                  configuration={{ size: isMobile ? 35 : 50 }}
                >
                  {title}{' '}
                  {completed ? (
                    <IconWrapper>
                      <IconCompleted
                        style={{ fontSize: 30 }}
                        className="icon-completato"
                      />
                    </IconWrapper>
                  ) : null}
                </Arial>
                <BannerInfo>
                  <Arial type="tojName" vocabulary={agenda_type === "live_session" ? "liveClassroomName" : "liveClassroomEventName"}>
                    {agenda_type === "live_session" ? 
                      getValueVocabulary(
                        vocabulary.liveClassroomName,
                        'liveClassroomName') :
                      getValueVocabulary(
                        vocabulary.liveClassroomEventName,
                        'liveClassroomEventName')}{' '}
                    | <span>{`${durationFormatHour(duration)} | ${slots?.length} ${getExactLabel(vocabulary, slots?.length, 'sessionLabel', 'sessionsLabel')}`}</span>
                  </Arial>
                </BannerInfo>
              </BannerContainer>
            </Banner>
            <Container>
              <TabsContainer>
                <Tabs
                  tabs={[...defaultTabs]}
                  onChange={onChangeTab}
                  selected={activeTab}
                  tabType={Tabs.TYPE.PRIMARY}
                  classList="liveClassroom"
                />
              </TabsContainer>
              {activeTab.value === 'overview' && (
                <OverviewContainer>
                  <Row className={isArabic() && 'd-flex flex-row-reverse'}>
                    <Col lg={mediaIsLaptop ? 12 : 8} md={12} sm={12}>
                      <Text>
                        <Title>
                          <Arial type="tojTitle" vocabulary="tojTitle">{getValueVocabulary(vocabulary.tojTitle, 'tojTitle')}</Arial>
                        </Title>
                        <Arial type="agendaTimelineDescription">
                          {' '}
                          <span
                            dangerouslySetInnerHTML={{ __html: goal?.description }}
                          />
                        </Arial>
                      </Text>
                      <AgendaTimeline
                        // timeline={agenda}
                        descriptionAgenda={descriptionAgenda}
                        vocabularyKey={"agendaListRemoteClassroom"}
                        //vocabularyKey={agenda_type === "live_session" ? "agendaListLiveClassroom" : "agendaListLiveClassroomEvent"}
                        classList="liveClassroom"
                      />
                      {sections?.length > 0 && (
                        <SectionContainer>
                          {sections?.map((section, index) => (
                            <Text key={`section--${index}`}>
                              <Title>
                                <Arial type="tojTitle">{section?.title}</Arial>
                              </Title>
                              <Arial type="agendaTimelineDescription">
                                {' '}
                                <span
                                  dangerouslySetInnerHTML={{ __html: section?.description }}
                                />
                              </Arial>
                            </Text>
                          ))}
                        </SectionContainer>
                      )}
                        <BoxFinalTest
                          slots={slots}
                          isClassroom
                          isLive={true}
                        />
                        <BoxAddress>
                          {/*{`${liveLocation?.street}, ${liveLocation?.number} - ${liveLocation?.cap} ${liveLocation?.city} (${liveLocation?.city?.substring(0,2)?.toUpperCase()})`}*/}
                          {`${liveLocation?.street}, ${liveLocation?.number} - ${liveLocation?.cap} ${liveLocation?.city}`}
                        </BoxAddress>
                    </Col>
                    <Col lg={mediaIsLaptop ? 12 : 4} md={12} sm={12}>
                      {mediaIsTablet || mediaIsLaptop ? (
                        <RightContainer>
                          <RightContainerItems>
                            <InfoContainer>
                              <InfoTitle><Arial type="author" vocabulary="guidelines">{getValueVocabulary(vocabulary.guidelines, 'guidelines')}</Arial></InfoTitle>
                              <InfoDescription>
                                <Arial type="productCardTitle" configuration={{ fontWeight: 'normal' }}>{getValueVocabulary(vocabulary.guidelinesLiveBody1, 'guidelinesLiveBody1')}{getValueVocabulary(vocabulary.guidelinesLiveBody2, 'guidelinesLiveBody2')}</Arial>
                              </InfoDescription>
                            </InfoContainer>
                          </RightContainerItems>
                          <RightContainerItems>
                            <TeacherBox
                              title="Teachers"
                              tutor={teachers.length ? teachers : tutee}
                            />
                            {user_type === 'student'
                              && completed
                              // && feedback && Object.keys(feedback).length > 0
                              && (
                                <LikeDislikeBox
                                  countDislike={feedback?.negative}
                                  countLike={feedback?.positive}
                                  disabled={user_type !== 'student'}                           
                                  title={"RemoteClassroomLikeDislikeBoxTitle"}
                                  //title={agenda_type === "live_session" ? "LiveClassroomLikeDislikeBoxTitle" : "LiveClassroomEventLikeDislikeBoxTitle"}
                                  isLiked={feedback?.tuteeVote === 0 ? null : feedback?.tuteeVote === 1}
                                  onChange={handleOnchangeLikeDislike}
                                />
                              )}
                          </RightContainerItems>
                        </RightContainer>
                      ) : (
                        <>
                          <TeacherBox
                            title={getValueVocabulary(vocabulary.teachersLabel, 'teachersLabel')}
                            tutor={teachers.length ? teachers : tutee}
                          />
                          <InfoContainer>
                            <InfoTitle><Arial type="author" vocabulary="guidelines">{getValueVocabulary(vocabulary.guidelines, 'guidelines')}</Arial></InfoTitle>
                            <InfoDescription>
                            <Arial type="productCardTitle" configuration={{ fontWeight: 'normal' }}>{getValueVocabulary(vocabulary.guidelinesLiveBody1, 'guidelinesLiveBody1')}{getValueVocabulary(vocabulary.guidelinesLiveBody2, 'guidelinesLiveBody2')}</Arial>
                            </InfoDescription>
                          </InfoContainer>
                          {user_type === 'student'
                            && completed
                            // && feedback && Object.keys(feedback).length > 0
                            && (
                              <LikeDislikeBox
                                countDislike={feedback?.negative}
                                countLike={feedback?.positive}
                                disabled={user_type !== 'student'}
                                title={"RemoteClassroomLikeDislikeBoxTitle"}
                                //title={agenda_type === "live_session" ? "LiveClassroomLikeDislikeBoxTitle" : "LiveClassroomEventLikeDislikeBoxTitle"}
                                isLiked={feedback?.tuteeVote === 0 ? null : feedback?.tuteeVote === 1}
                                onChange={handleOnchangeLikeDislike}
                              />
                            )}
                        </>
                      ) }
                    </Col>
                  </Row>
                </OverviewContainer>
              )}
              {activeTab.value === 'materials' && (
                <>
                  <GuidebookListColumn md={9} sm={12} lg={12}>
                    <RowGuidebook>
                      {(materials || []).map((singleMaterials, i) => (
                        <GuidebookItemColumn key={i}>
                          <GuideboookCard guidebook={singleMaterials} />
                        </GuidebookItemColumn>
                      ))}
                    </RowGuidebook>
                  </GuidebookListColumn>
                </>
              )}
            </Container>
          </React.Fragment>
        ) : (
          <CourseNotAvailable>
            <Arial type="sectionNotificationTitle" configuration={{ textAlign: 'center' }} vocabulary="contentNotAvailableMessage">
              {getValueVocabulary(vocabulary.contentNotAvailableMessage, 'contentNotAvailableMessage')}
            </Arial>
          </CourseNotAvailable>
        )}

    </Wrapper>
  );
};

LiveClassroom.propTypes = {
  heroImage: PropTypes.string,
  mediaIsLaptop: PropTypes.bool,
  mediaIsTablet: PropTypes.bool,
  id: PropTypes.string.isRequired,
  // HOC (connect, dispatch)
  getLiveClassroomContent: PropTypes.func,
  setLiveClassroomFeedback: PropTypes.func,
  // HOC (connect, state)
  selected_classroom: PropTypes.object,
  contentNotAvailable: PropTypes.bool,
  location: PropTypes.object,
  user_type: PropTypes.string,
  vocabulary: PropTypes.object,
};

export default connect(
  state => {
    const { selected_classroom, contentNotAvailable } = state.liveclassroom;
    const liveLocation = state.liveclassroom?.selected_classroom?.location;
    const {
      data: { user_type },
    } = state.user;
    const { vocabulary } = state.app;
    const { location } = state.router;
    return {
      selected_classroom,
      contentNotAvailable,
      user_type,
      vocabulary,
      location,
      liveLocation
    };
  },
  dispatch => ({
    getLiveClassroomContent: ({ id }) => dispatch({ type: LIVE_CLASSROOM_CONTENT_GET._REQUEST, id }),
    setLiveClassroomFeedback: (payload) => dispatch({ type: LIVE_CLASSROOM_LIKE_DISLIKE._REQUEST, payload }),
    pushUrl: url => dispatch(push(url)),
  })
)(withMediaQueries(LiveClassroom));
