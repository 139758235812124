import { all } from 'redux-saga/effects';
import appSagas from './app';
import authSagas from './auth';
import homeSagas from './home';
import searchSagas from './search';
import userSagas from './user';
import notificationSagas from './notification';
import favourite from './favourite';
import tojSagas from './toj';
import tojSurveySagas from './tojsurvey';
import remoteclassroomSagas from './remoteclassroom';
import liveclassroomSagas from './liveclassroom';
import remoteClassroomSurveySagas from './remoteclassroomsurvey';
import mandatorytraining from './mandatorytraining';
import optionalcourses from './optionalcourses';
import learningevents from './learningevents';

export default function* rootSaga() {
  yield all([
    ...appSagas,
    ...authSagas,
    ...homeSagas,
    ...searchSagas,
    ...userSagas,
    ...notificationSagas,
    ...favourite,
    ...tojSagas,
    ...tojSurveySagas,
    ...remoteclassroomSagas,
    ...liveclassroomSagas,
    ...remoteClassroomSurveySagas,
    ...mandatorytraining,
    ...optionalcourses,
    ...learningevents
  ]);
}
