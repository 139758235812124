import styled, { css } from 'styled-components';

export const BoxFinalTestContent = styled.div`
  min-height: 30px;
  padding: 0px 20px;
  display: flex;
`;

export const Icon = styled.span`
  font-size: 20px;
  ${props => props.visibleBody
    && css`
      transform: rotate(-180deg);
    `}
`;

export const HeaderWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  flex-direction: ${props => (props.isLive ? 'column' : 'row')};

  @media ${props => props.theme.device.mobileL} {
    flex-direction: column; 
    & > div {
      margin-top: 10px;
    }
  }
`;

export const ButtonContainer = styled.div`
  & > button {
    width: 180px;
    height: 40px;
  }

  & > a {
    @media ${props => props.theme.device.tabletL} {
      margin: 0;
    }
  }

  ${props => props.link
    && css`
      margin-bottom: 20px;
    `};
`;

export const BoxFinalTestContainer = styled.div`
  padding: 20px 0px;
  background-color: rgba(0, 0, 0, 0.5);
  min-width: 200px;
  height: auto;
  margin-bottom: 15px;
  border-radius: 4px;
  border: 1px solid #5c5c5c;

  ${props => props.disabled
    && css`
      opacity: 0.5;
      cursor: not-allowed;
    `}

    ${({ arabic }) => arabic && css`
      ${HeaderWrap} {
        flex-direction: row-reverse;
        @media ${props => props.theme.device.mobileL} {
          flex-direction: ${props => (props.isToj ? 'row-reverse' : 'column')};
        }
      }
  `}
`;
