import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import FullCalendar from '@fullcalendar/react';
import allLocales from '@fullcalendar/core/locales-all';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';

import { connect } from 'react-redux';
import queryString from 'query-string';
import { push } from 'redux-first-history';
import {
  BoxContainer, Header, Body, CalendarContainer, NoResultContainer,
  TitleAgenda, NumEvents, SelectedDate, TitleBox, BlackContainer,
  PlannedListContainer, PlannedListRow, PlannedListCol,
  PlannableListContainer, PlannableListRow, PlannableListCol,
  ViewMore,
} from './style';

import Arial from '../../../ui/typography/arial';

import { withMediaQueries } from '../../hoc/withMediaQueries';
import { parseQueryParams } from '../../../utils/queryParams';
import routes from '../../../routes';
import { CardEventsCourses, CardLearningEvents, NoResult } from '../../../ui/atoms';
import { getValueVocabulary } from '../../../utils/getValueVocabulary';
import { CustomCarousel } from '../../../ui/components';
import { BaseSettings } from './settings';
import {
  LEARNING_EVENTS_PLANNABLE_LIST_GET,
  LEARNING_EVENTS_PLANNED_LIST_GET,
} from '../../redux/actions/learningevents';
import { isArabic, toArabicNumbers, getDefaultLanguage } from '../../../utils/locale';
import { dateFormatCalendar } from '../../../utils/common';


const LearningEvents = ({
  vocabulary, mediaIsPhone,
  getPlannedList, getPlannableList,
  plannedList, plannableList, pager,
  location,
  // user_default_language,
  pushUrl,
}) => {
  const { selectedDate } = queryString.parse(location.search);
  const [start, setStart] = useState();
  const [end, setEnd] = useState();

  useEffect(() => {
    if (start && end) {
      getPlannedList({ start_date_timestamp: start * 1000, end_date_timestamp: end * 1000 });
    }
    window.scrollTo(0, 0);
  }, [getPlannedList, start, end]);

  useEffect(() => {
    getPlannableList({});
  }, [getPlannableList]);

  const [events, setEvents] = useState([]);
  const [selectedEvents, setSelectedEvents] = useState([]);

  const fullcalendarRef = useRef(null);
  let calendarApi; fullcalendarRef.current && !calendarApi && (calendarApi = fullcalendarRef.current.getApi());

  useLayoutEffect(() => {
    /* this side effect patches a localization mistake in the FullCalendar package */
    if (!calendarApi) return;
    const calendarTitle = calendarApi.view.getCurrentData().viewTitle;
    const newTitle =
      moment.locale() === 'bs' ? moment(calendarTitle.replace(' M', '-')).format('MMMM YYYY')
        : moment.locale() === 'sq' ? moment(calendarTitle).format('MMMM YYYY')
          : null;
    if (newTitle) document.querySelector('.fc-toolbar-title').innerText = newTitle;
  });

  useEffect(() => {
    const selectedEventsList = events.filter(event => (event.start && event.end
      ? moment(selectedDate ? new Date(selectedDate * 1000) : new Date()).isSame(moment(new Date(event.start)), 'day')
      || (moment(selectedDate ? new Date(selectedDate * 1000) : new Date()).isAfter(moment(new Date(event.start))) && moment(selectedDate ? new Date(selectedDate * 1000) : new Date()).isBefore(moment(new Date(event.end))))
      : moment(selectedDate ? new Date(selectedDate * 1000) : new Date()).isSame(moment(new Date(event.start)), 'day')));
    setSelectedEvents(selectedEventsList);
    if (!selectedDate) pushUrl(`${routes.learningevents.path}${parseQueryParams({ selectedDate: moment(new Date()).unix() })}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [events, selectedDate]);

  useEffect(() => {
    if (selectedDate && !mediaIsPhone && calendarApi) {
      calendarApi.gotoDate(new Date(selectedDate * 1000));

      const selectedDateTile = moment(new Date(selectedDate * 1000)).format('YYYY-MM-DD');
      const dayTiles = document.querySelectorAll('.fc-daygrid-day') || [];
      dayTiles.forEach((tile) => (tile.getAttribute('data-date') === selectedDateTile ? tile.classList.add('active-tile') : tile.classList.remove('active-tile')));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate]);

  if (selectedDate && !mediaIsPhone) {
    const selectedDateTile = moment(new Date(selectedDate * 1000)).format('YYYY-MM-DD');
    const dayTiles = document.querySelectorAll('.fc-daygrid-day') || [];
    dayTiles.forEach((tile) => (tile.getAttribute('data-date') === selectedDateTile ? tile.classList.add('active-tile') : tile.classList.remove('active-tile')));
  }

  useEffect(() => {
    const formattedToj = (plannedList || []).reduce((acc, value) => {
      const tojArray = value.values.slots.map(slot => ({
        slot,
        ...value.values,
        agenda_type: value.type,
        className: value.type,
      }));
      return [...acc, ...tojArray];
    }, []);

    const formattedEvents = [...formattedToj].filter(event => !!event.calendar_date || !!event.soft_expiration_date || !!event.slot).map(event => {
      const { calendar_date, soft_expiration_date, agenda_type } = event;
      switch (agenda_type) {
        case 'toj':
        case 'remote_classroom':
        case 'live_event':
        case 'live_session':
          return {
            ...event,
            start: new Date(event.slot.startDateTimestamp),
            end: new Date(event.slot.endDateTimestamp),
          };
        default:
          return {
            ...event,
            start: calendar_date ? new Date(calendar_date * 1000) : new Date(soft_expiration_date * 1000),
          };
      }
    }).sort((a, b) => new Date(a.start) - new Date(b.start));
    setEvents([...formattedEvents]);
  }, [plannedList]);

  const onClickDate = (calendarDate) => {
    const { date } = calendarDate;
    pushUrl(`${routes.learningevents.path}${parseQueryParams({ selectedDate: moment(date || calendarDate).unix() })}`);
  };

  const onClickEvent = (eventInfo) => {
    const { event: { start: date = new Date() } } = eventInfo;
    pushUrl(`${routes.learningevents.path}${parseQueryParams({ selectedDate: moment(date).unix() })}`);
  };

  const setStartEnd = (fetchInfo) => {
    setStart(moment(fetchInfo?.start).unix());
    setEnd(moment(fetchInfo?.end).unix());
    return events;
  };

  const { defaultLanguage } = getDefaultLanguage();

  const config = {
    plugins: [dayGridPlugin, interactionPlugin],
    initialView: 'dayGridMonth',
    fixedWeekCount: false,
    // locale: user_default_language.toLowerCase(),
    locale: defaultLanguage,
    locales: allLocales,
    dateClick: onClickDate,
    eventClick: onClickEvent,
    dayMaxEvents: 1,
    ref: fullcalendarRef,
    events: (fetchInfo, successCallback, failureCallback) => {
      const datas = setStartEnd(fetchInfo);
      if (datas?.length) {
        successCallback(datas);
      }
      failureCallback([]);
    },
  };

  const redirectLearningEventsDetails = (item) => {
    const {
      id, tutee_id, role, deadline,
    } = item;
    pushUrl(`${routes.toj.path}/${id}${parseQueryParams({ tutee_id, role, deadline })}`);
  };

  const onClickViewMore = () => {
    getPlannableList({ subset: true, loader: false });
  };

  return (
    <>
      <PlannedListContainer arabic={isArabic()}>
        <TitleAgenda>
          {getValueVocabulary(vocabulary.learningEvents, 'learningEvents')}
        </TitleAgenda>
        <PlannedListRow>
          <PlannedListCol>
            <CalendarContainer>
              {mediaIsPhone ? (
                <Calendar
                  view="month"
                  tileClassName={({ date }) => (events.filter(event => moment(new Date(date)).isSame(moment(new Date(event.start)), 'day')).length > 0 ? 'active' : '')}
                  onClickDay={onClickDate}
                />
              ) : (
                <FullCalendar
                  {...config}
                />
              )}
            </CalendarContainer>
          </PlannedListCol>
          <PlannedListCol>
            <TitleBox>
              {getValueVocabulary(vocabulary.eventsCourses, 'eventsCourses')}
            </TitleBox>
            <BoxContainer>
              <Header>
                <SelectedDate>
                  <Arial type="description14">{moment(new Date(selectedDate * 1000))?.format('dddd')} </Arial>
                  <Arial type="description14">{dateFormatCalendar(selectedDate * 1000)}</Arial>
                </SelectedDate>
                {selectedEvents.length > 0 && (
                  <NumEvents>
                    <Arial type="description14">{selectedEvents.length}</Arial>
                    <Arial type="description14">{`${selectedEvents.length === 1 ? ` ${getValueVocabulary(vocabulary.eventLabel, 'eventLabel')}` : ` ${getValueVocabulary(vocabulary.eventsLabel, 'eventsLabel')}`} `}</Arial>
                  </NumEvents>
                )}
              </Header>
              <Body>
                {selectedEvents.length > 0 ? (
                  <CustomCarousel config={BaseSettings}>
                    {
                      selectedEvents.map(item => <CardEventsCourses key={`card-event-${item.id}`} item={item} selectedDate={selectedDate} />)
                    }
                  </CustomCarousel>
                ) : <NoResultContainer><NoResult text={getValueVocabulary(vocabulary.noActivity, 'noActivity')} /></NoResultContainer>}
              </Body>
            </BoxContainer>
          </PlannedListCol>
        </PlannedListRow>
      </PlannedListContainer>
      <BlackContainer arabic={isArabic()}>
        <PlannableListContainer>
          <Arial type="eventToScheduleLabel" vocabulary="eventToScheduleLabel">{getValueVocabulary(vocabulary.eventToScheduleLabel, 'eventToScheduleLabel')}</Arial>
          <PlannableListRow>
            {
              plannableList.map(item => (
                <PlannableListCol key={`learning-event-${item.values?.id}`}>
                  <CardLearningEvents onClickCard={() => redirectLearningEventsDetails(item.values)} item={item} />
                </PlannableListCol>
              ))
            }
          </PlannableListRow>
        </PlannableListContainer>
        {plannableList.length <= 0 && <NoResult />}
        {pager?.current_page < pager?.total_pages && (
          <ViewMore>
            <Arial type="viewMore" vocabulary="viewMore" onClick={onClickViewMore}>{getValueVocabulary(vocabulary.viewMore, 'viewMore')}</Arial>
          </ViewMore>
        )}
      </BlackContainer>
    </>
  );
};

LearningEvents.propTypes = {
  mediaIsPhone: PropTypes.bool.isRequired,
  mediaIsTablet: PropTypes.bool.isRequired,
  // HOC (connect, state)
  vocabulary: PropTypes.object.isRequired,
  plannedList: PropTypes.array.isRequired,
  plannableList: PropTypes.array.isRequired,
  pager: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  // user_default_language: PropTypes.string.isRequired,
  // HOC (connect, dispatch)
  getPlannedList: PropTypes.func.isRequired,
  getPlannableList: PropTypes.func.isRequired,
  pushUrl: PropTypes.func.isRequired,
};

export default connect(
  (state) => {
    const { vocabulary } = state.app;
    const { plannedList, plannableList, pager = {} } = state.learningevents;
    const { location } = state.router;
    // const { data: { lang: user_default_language = '' } } = state.user;

    return {
      vocabulary, plannedList, plannableList, pager, location, //user_default_language
    };
  },
  dispatch => ({
    getPlannedList: (filters) => dispatch({ type: LEARNING_EVENTS_PLANNED_LIST_GET._REQUEST, filters }),
    getPlannableList: ({ subset, loader }) => dispatch({ type: LEARNING_EVENTS_PLANNABLE_LIST_GET._REQUEST, subset, loader }),
    pushUrl: url => dispatch(push(url)),
  })
)(withMediaQueries(LearningEvents));
