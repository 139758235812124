import {
    takeLatest, put, call,
  } from 'redux-saga/effects';
  
  import { push } from 'redux-first-history';
  import { LOADER_CLOSE, LOADER_OPEN } from '../actions';
  
  import {
    LIVE_CLASSROOM_CONTENT_GET,
    LIVE_CLASSROOM_COMMENTS_GET,
    LIVE_CLASSROOM_COMMENTS_SET,
    LIVE_CLASSROOM_LIKE_DISLIKE,
  } from '../actions/liveclassroom';
  import liveclassroom from '../../api/liveclassroom';
  import routes from '../../../routes';
  
  function* getLiveClassroomContent({ id, loader = true }) {
    try {
      if (loader) yield put({ type: LOADER_OPEN });
      const { data: { data: response = {} } = {} } = yield call(liveclassroom.getLiveClassroomContent, { id });
      yield put({ type: LIVE_CLASSROOM_CONTENT_GET._SUCCESS, response });
    } catch (error) {
      yield put({ type: LIVE_CLASSROOM_CONTENT_GET._ERROR, error });
      yield put(push(routes.home.path));
      // eslint-disable-next-line no-console
      console.log('sagas > liveclassroom > getLiveClassroomContent', error);
    } finally {
      if (loader) yield put({ type: LOADER_CLOSE });
    }
  }
  
  function* getLiveClassroomContentWatch() {
    yield takeLatest(LIVE_CLASSROOM_CONTENT_GET._REQUEST, getLiveClassroomContent);
  }
  
  function* liveClassroomCommentsGet({ id }) {
    if (id) {
      try {
        yield put({ type: LOADER_OPEN });
        const { data: comments } = yield call(liveclassroom.getLiveClassroomComments, { id });
        yield put({ type: LIVE_CLASSROOM_COMMENTS_GET._SUCCESS, comments });
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log('sagas > liveclassroom > liveClassroomCommentsGet', err);
      } finally {
        yield put({ type: LOADER_CLOSE });
      }
    }
  }
  
  function* liveClassroomCommentsGetWatch() {
    yield takeLatest(LIVE_CLASSROOM_COMMENTS_GET._REQUEST, liveClassroomCommentsGet);
  }
  
  function* liveClassroomCommentsSet({ id, subject, comment_body }) {
    try {
      yield put({ type: LOADER_OPEN });
      const payload = {
        entity_id: [{ target_id: id }],
        entity_type: [{ value: 'node' }],
        comment_type: [{ target_id: 'comment' }],
        field_name: [{ value: 'comment' }],
        subject: [{ value: subject }],
        comment_body: [{ value: comment_body }],
      };
      yield call(liveclassroom.setLiveClassroomComments, payload);
      yield put({ type: LIVE_CLASSROOM_COMMENTS_SET._SUCCESS });
      yield put({ type: LIVE_CLASSROOM_COMMENTS_GET._REQUEST, id });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('sagas > liveclassroom > liveClassroomCommentsSet', err);
    } finally {
      yield put({ type: LOADER_CLOSE });
    }
  }
  
  function* liveClassroomCommentsSetWatch() {
    yield takeLatest(LIVE_CLASSROOM_COMMENTS_SET._REQUEST, liveClassroomCommentsSet);
  }
  
  
  function* setLikeDislikeFeedback({ payload }) {
    try {
      const { feedback_value, id } = payload;
      yield call(liveclassroom.setLikeDislikeFeedback, { feedback_value, id });
      yield put({ type: LIVE_CLASSROOM_LIKE_DISLIKE._SUCCESS });
      yield put({ type: LIVE_CLASSROOM_CONTENT_GET._REQUEST, id, loader: false });
  
      //yield put(push(`${routes.toj.path}/${tojId}`));
    } catch (err) {
      yield put({ type: LIVE_CLASSROOM_LIKE_DISLIKE._ERROR });
      // eslint-disable-next-line no-console
      console.log('sagas > liveclassroom > setLikeDislikeFeedback', err);
    }
  }
  
  function* setLikeDislikeFeedbackWatch() {
    yield takeLatest(LIVE_CLASSROOM_LIKE_DISLIKE._REQUEST, setLikeDislikeFeedback);
  }
  
  export default [
    getLiveClassroomContentWatch(),
    liveClassroomCommentsGetWatch(),
    liveClassroomCommentsSetWatch(),
    setLikeDislikeFeedbackWatch(),
  ];
  