import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';

// eslint-disable-next-line import/no-cycle
import { isArabic } from '../../../utils/locale';
import {
  BoxFinalTestContainer, Icon, BoxFinalTestContent, HeaderWrap, ButtonContainer,
} from './style';
import { SlotTitle } from '../../../app/pages/RemoteClassroom/style';
import Arial from '../../typography/arial';
import { Link } from '../../../app/pages/RemoteClassroom/style';
import { getValueVocabulary } from '../../../utils/getValueVocabulary';
import { dateFormatCalendar } from '../../../utils/common';

const BoxFinalTest = ({
  title, disabled, isToj, link, isClassroom, canPartecipate, vocabulary, isLive, slots
}) => {
  const convertUrlParticipate = url => (url.indexOf('http') < 0 ? `https:\\\\${url}` : url);

  const getSlotTitle = (slot) => (
    <SlotTitle className='mb-2'>
      <Arial vocabulary="slotTitleSchedule">
        <Arial vocabulary="slotDate" type="slotDate" configuration={{ color: '#fff' }}>{dateFormatCalendar(slot.startDateTimestamp)}</Arial>
        <Arial type="scheduledTime">{`${moment(new Date(slot.startDateTimestamp)).format('h:mm A')} - ${moment(new Date(slot.endDateTimestamp)).format('h:mm A')}`}</Arial>
      </Arial>
    </SlotTitle>
  );

  return (
    <BoxFinalTestContainer isToj={isToj} arabic={isArabic()} disabled={disabled}>
      <BoxFinalTestContent onClick={() => {}}>
        <HeaderWrap isToj={isToj} isLive={isLive}>
          {!isLive && <Arial type="slotTitleAR">{title}</Arial>}
          {isLive && (slots || []).map((slot, index) => (
            <Arial type="slotTitleAR">{getSlotTitle(slot, index + 1)}</Arial>
          ))}
          {isToj ? <Icon className="icon-arrow-right" />
            : !isLive && (
              <ButtonContainer>
                <Link
                  href={link ? convertUrlParticipate(link) : ''}
                  target="_blank"
                  isClassroom={isClassroom}
                  disabled={!canPartecipate}
                >
                  {getValueVocabulary(vocabulary.joinButtonLabel, 'joinButtonLabel')}
                </Link>
              </ButtonContainer>
            )}
        </HeaderWrap>
      </BoxFinalTestContent>
    </BoxFinalTestContainer>
  );
};
BoxFinalTest.propTypes = {
  title: PropTypes.string,
  isToj: PropTypes.bool,
  link: PropTypes.string,
  disabled: PropTypes.bool,
  isClassroom: PropTypes.bool,
  isLive: PropTypes.bool,
  slots: PropTypes.array
};

export default connect(
  state => {
    const { vocabulary } = state.app;
    return {
      vocabulary,
    };
  }
)(BoxFinalTest);
