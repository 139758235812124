import './ui/scss/app.scss';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';
import 'react-toastify/dist/ReactToastify.css';
import {
  Router, Route, Switch, Redirect
} from 'react-router-dom';

import 'moment/locale/it';
import 'moment/locale/ar';
import 'moment/locale/ro';
import 'moment/locale/sq';
import 'moment/locale/hu';
import 'moment/locale/sr';
import 'moment/locale/hr';
import 'moment/locale/sl';
import 'moment/locale/zh-cn';
import 'moment/locale/bs';
import 'moment/locale/sk';
import 'moment/locale/uk';

import './ui/scss/bootstrap/bootstrap-grid.min.css';

import moment from 'moment';
import theme from './ui/theme';
import routes from './routes';
import { history } from './app/redux/store';

import { GlobalLayout, Loader } from './ui/components';

/* Pages */
import {
  Auth, AuthReset, Home, Search, Faq,
  Notifications, LearningEvents, Toj,
  TojSurvey, RemoteClassroomSurvey, Maintenance,
  MandatoryTraining, OptionalCourses, MandatoryTrainingDetails,
  Favourites, OptionalCoursesDetails
} from './app/pages';
import { APP_STORE_RELOAD } from './app/redux/actions';
import { ModalsPortal } from './ui/components/Modals';
import ErrorModal from './ui/components/Modals/ErrorModal';
import RemoteClassroom from './app/pages/RemoteClassroom';
import LiveClassroom from './app/pages/LiveClassroom';
import TojSurveySession from './app/pages/TojSurveySession';
import storage from './utils/storage';
import { shouldUseMaintenance } from './utils/common';
import { getCountry, getDefaultLanguage } from './utils/locale';
import Toast from './ui/components/Toast';

const App = ({ appStoreReload, isLogged,
  // user_default_language
}) => {
  const { defaultLanguage, isInStorage } = getDefaultLanguage();
  moment.locale(defaultLanguage !== 'zh-hans' ? defaultLanguage : 'zh-cn');
  !isInStorage && storage.write('default_language', defaultLanguage);

  useEffect(() => {
    function populateStorage() {
      const { value } = getCountry()
      storage.write('default_language', value);
      window.location.reload()
    };
    window.addEventListener('storage', populateStorage);
    return () => window.removeEventListener('storage', populateStorage);
  }, []);

  useEffect(() => {
    appStoreReload();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ThemeProvider theme={theme('themeBlack')}>
      <Router history={history}>
        {isLogged && !shouldUseMaintenance() ? (
          <GlobalLayout>
            <Switch>
              <Route
                path={routes.home.path}
                exact
                render={() => (
                  <Home />
                )}
              />
              <Route
                path={routes.notifications.path}
                render={() => <Notifications history={history} />}
                exact
              />
              <Route
                path={routes.favourites.path}
                render={() => <Favourites />}
                exact
              />
              <Route
                path={`${routes.mandatorytraining.path}/:course_id/:lo_id`}
                render={({ match: { params: { course_id = '', lo_id = '' } = {} } = {} }) => <MandatoryTrainingDetails course_id={course_id} lo_id={lo_id} />}
                exact
              />
              <Route
                path={`${routes.mandatorytraining.path}/:course_id`}
                render={({ match: { params: { course_id = '' } = {} } = {} }) => <MandatoryTrainingDetails course_id={course_id} />}
                exact
              />
              <Route
                path={routes.mandatorytraining.path}
                render={() => <MandatoryTraining />}
                exact
              />
              <Route
                path={`${routes.optionalcourses.path}/:course_id/:lo_id`}
                render={({ match: { params: { course_id = '', lo_id = '' } = {} } = {} }) => <OptionalCoursesDetails course_id={course_id} lo_id={lo_id} />}
                exact
              />
              <Route
                path={`${routes.optionalcourses.path}/:course_id`}
                render={({ match: { params: { course_id = '' } = {} } = {} }) => <OptionalCoursesDetails course_id={course_id} />}
                exact
              />
              <Route
                path={routes.optionalcourses.path}
                render={() => <OptionalCourses />}
                exact
              />
              <Route
                path={routes.learningevents.path}
                render={() => <LearningEvents />}
                exact
              />
              <Route
                path={`${routes.remoteclassroom.path}/:id`}
                render={({ match: { params: { id = '' } = {} } = {} }) => (
                  <RemoteClassroom id={id} />
                )}
                exact
              />
              <Route
                path={`${routes.liveclassroom.path}/:id`}
                render={({ match: { params: { id = '' } = {} } = {} }) => (
                  <LiveClassroom id={id} />
                )}
                exact
              />
              <Route
                path={`${routes.remoteclassroom.path}/:id/survey`}
                render={({ match: { params: { id = '' } = {} } = {} }) => (
                  <RemoteClassroomSurvey remoteClassroomId={id} />
                )}
                exact
              />
              <Route
                path={`${routes.toj.path}/:id`}
                render={({ match: { params: { id = '' } = {} } = {} }) => (
                  <Toj tojId={id} />
                )}
                exact
              />
              <Route
                path={`${routes.toj.path}/:id/survey`}
                render={({ match: { params: { id = '' } = {} } = {} }) => (
                  <TojSurvey tojId={id} />
                )}
                exact
              />
              <Route
                path={`${routes.toj.path}/:id/surveySession`}
                render={({ match: { params: { id = '' } = {} } = {} }) => (
                  <TojSurveySession tojId={id} />
                )}
                exact
              />
              <Route path={routes.faq.path} render={() => <Faq />} exact />
              <Route
                path={`${routes.search.path}`}
                exact
                render={props => (
                  <Search {...props} title={routes.search.title} />
                )}
              />
              <Redirect to={routes.home.path} />
            </Switch>
          </GlobalLayout>
        ) : (
          <>
            {
              !shouldUseMaintenance()
                ? (
                  <Switch>
                    <Route path={routes.resetAuth.path} render={() => <AuthReset />} />
                    <Route path={routes.auth.path} render={() => <Auth />} />
                    <Redirect to={routes.auth.path} />
                  </Switch>
                )
                : (
                  <Switch>
                    <Route path={`${routes.maintenance.path}`} exact render={() => <Maintenance />} />
                    <Redirect to={routes.maintenance.path} />
                  </Switch>
                )
            }
          </>
        )}
        <Loader />
      </Router>
      <ErrorModal />
      <Toast />
      <ModalsPortal.Target />
    </ThemeProvider>
  );
};

App.propTypes = {
  // HOC (connect, state)
  isLogged: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
  user_default_language: PropTypes.string,

  // HOC (connect, dispatch)
  appStoreReload: PropTypes.func.isRequired
};

export default connect(
  state => {
    const { isLogged } = state.app.authState;
    const { location } = state.router;
    // const { data: { lang: user_default_language = '' } } = state.user;

    return {
      isLogged,
      location,
      // user_default_language
    };
  },
  dispatch => ({
    appStoreReload: () => dispatch({ type: APP_STORE_RELOAD })
  })
)(App);
